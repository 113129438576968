import vex from 'vex-js';
import vexDialog from 'vex-dialog';

import qrWechat from '../img/qr_moynat.jpg';
import qrStoreLocator from '../img/qr_storelocator.jpg';

import 'vex-js/dist/css/vex.css';

vex.defaultOptions.className = 'vex-default';

vex.registerPlugin(vexDialog);

function handleWeChatClick () {
	vex.dialog.open({
		message: '请扫描二维码关注MOYNAT官方微信',
		className: 'wechat-dialog',
		input: [
			'<div class="dialog-content">',
				'<img src="' + qrWechat + '" class="qr code wechat">',
			'</div>',
		].join(''),
		showCloseButton: true
	});
}

function handleMapClick () {
	vex.dialog.open({
		message: '请扫描二维码查找MOYNAT精品店',
		className: 'map-dialog',
		input: [
			'<div class="dialog-content">',
				'<img src="' + qrStoreLocator + '" alt="qr code store locator">',
			'</div>',
		].join(''),
		showCloseButton: true
	});
}

document.getElementById('btn-wechat').addEventListener('click', handleWeChatClick, false);
document.getElementById('btn-map').addEventListener('click', handleMapClick, false);
